<template>
  <div class="phone_div">
        <van-nav-bar
        left-text="返回"
        left-arrow
        :title="model[type].title"
        @click-left="back"/>
        <van-form @submit="onSubmit">
            <van-cell-group>
                <van-field
                    v-model="form.phone"
                    required
                    label="手机号"
                    :rules="[{ validator:$util.checkPhone, message: '请输入正确的手机号码' }]"
                    placeholder="请输入手机号"
                />
                <van-field
                    v-model="form.sms"
                    center
                    clearable
                    label="短信验证码"
                    :rules="[{ required:true, message: '请输入正确的短信验证码' }]"
                    placeholder="请输入短信验证码">
                    <template #button>
                        <van-button size="small" @click="sendSMS" :disabled='index<60' native-type="button" type="primary">{{smsText}}</van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block type="info"  native-type="submit">提交</van-button>
                </div>
            </van-cell-group>
        </van-form>
  </div>
</template>

<script>
import { Button,NavBar,Field,CellGroup,Form} from 'vant';
import {payPhoneSendMsg,bindPhone} from '../../api/common'
import {allinpayUser} from '../../store/allinpay'
import {providerInfo} from '../../store/provider'
export default {
    components:{
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [Button.name]: Button,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
    },
    data(){
        return {
            indexUrl:'',
            smsText:'发送验证码',
            index:60,
            form:{
                phone:'',
                sms:'',
            },
            model:{
                0:{title:'绑定手机号码'},
                1:{title:'解绑手机号码'}
            },
            type:0,
            applyData:'',
            scrapUserId:'',
            isFirst:false
        }
    },
    methods:{
        back(){
            this.$router.go(-1);
            // if(this.scrapUserId){
            //     this.$router.go(-1);
            // }else{
            //     this.$router.replace(this.indexUrl);
            // }
        },
        async sendSMS(){
            var user = allinpayUser.get();
            if(!this.$util.checkPhone(this.form.phone)){
                this.$toast.fail("请输入正确的手机号码");
                return;
            }else{
                this.index = 59;    
                var interval = setInterval(()=>{
                    this.smsText = this.index;
                    this.index--;
                    if(this.index<0){
                        this.smsText = '重新发送';
                        this.index = 60;
                        window.clearInterval(interval);
                    }
                },1000);
                var body;
                var type = user.memberType;
                if(this.scrapUserId){
                    body = {
                        userId:this.scrapUserId,
                        userSource:2,
                        phone:this.form.phone,
                        verificationCodeType:9,
                        baseMemberType:type||''
                    };
                }else{
                    body={
                        userId:user.userId,
                        userSource:user.userSource,
                        phone:this.form.phone,
                        verificationCodeType:9,
                        baseMemberType:type||''
                    };
                }
                payPhoneSendMsg(body).then(res=>{
                    this.$toast({type:res.status?'success':'fail',message:res.message});
                });
            }
        },
        onSubmit(){
            var body;
            var user = allinpayUser.get();
            if(this.scrapUserId){
                body = {
                    "userId": this.scrapUserId,
                    "userSource": 2,
                    "phone": this.form.phone,
                    "verificationCode": this.form.sms
                };
            }else{
                body = {
                    "userId": user.userId,
                    "userSource": user.userSource,
                    "phone": this.form.phone,
                    "verificationCode": this.form.sms
                };
            }
            
            this.$toast.loading({duration: 0,forbidClick: true,message: '绑定中'});
            bindPhone(body).then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.message});
                if(res.status||res.message == '手机已绑定'){
                    if(this.scrapUserId){
                        this.$router.go(-1);
                    }else{
                        user.allinPayPhome = this.form.phone;
                        if(user.newUser){
                            var provider = providerInfo.get();
                            allinpayUser.update({mobile:this.form.phone})
                            this.$router.push({path:'/magent/register',query:{id:provider.id}});
                        }else{
                            this.$router.push('/bank/wallet');
                        }
                    }
                }else{
                    return;
                }
            });
        }
    },
    created(){
        this.scrapUserId = this.$route.query.scrapUserId;
        this.isFirst = !!this.$route.query.first;
    }
}
</script>

<style>

</style>